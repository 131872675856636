import React, { Fragment } from "react";
import Flex from "src/components/UI/Structure/Flex";
import Label from "src/components/UI/Typography/Label";
import Text from "src/components/UI/Text";

const Territories = ({ label, territories }) => {
  return (
    <Fragment>
      {territories && label && (
        <Label fontSize={[2, null, null, null, 4, 5]}>{label}</Label>
      )}
      {territories && (
        <Flex flexDirection="column">
          {territories.map(({ territory }, i) => (
            <Text key={i} fontSize={[2, null, null, null, 4, 5]}>
              {territory}
            </Text>
          ))}
        </Flex>
      )}
    </Fragment>
  );
};

export default Territories;
