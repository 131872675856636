import React, { Fragment } from "react";
import { StaticQuery, graphql } from "gatsby";
import { format, addHours } from "date-fns";
import svLocale from "date-fns/locale/sv";
import Events from "src/components/Modules/Events/Events";
const sortEventsByMonth = function (events) {
  // Group events by year and month into object
  const eventGroups = events.reduce((group, event) => {
    let month = format(new Date(event.acfEventPage.date), "MMMM", {
      locale: svLocale,
    });
    let monthAndYear = format(new Date(event.acfEventPage.date), "MMMM yyyy", {
      locale: svLocale,
    });
    group[monthAndYear]
      ? group[monthAndYear].events.push(event)
      : (group[monthAndYear] = { label: month, events: [event] });
    return group;
  }, {});

  // Put group objects in array with label and events
  return Object.keys(eventGroups).map(function (k) {
    return eventGroups[k];
  });
};

const ArtistEvents = ({ data, artist, label }) => {
  const today = Date.now();
  const upcomingArtistEvents = data.allWpEvent.edges
    .map(({ node: event }) => event)
    .filter((event) => {
      return addHours(new Date(event.acfEventPage.date), 22) >= today;
    })
    .filter((event) => {
      return event.acfEventPage.artist.databaseId === artist.databaseId;
    });

  const artistEventsGroupedByMonth = sortEventsByMonth(upcomingArtistEvents);

  return (
    <Fragment>
      {artistEventsGroupedByMonth?.length > 0 &&
        artistEventsGroupedByMonth.map((eventGroup, i) => (
          <Events key={i} label={eventGroup.label} events={eventGroup.events} />
        ))}
    </Fragment>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allWpEvent(sort: { fields: [acfEventPage___date], order: ASC }) {
          edges {
            node {
              title
              acfEventPage {
                artist {
                  ... on WpArtist {
                    title
                    databaseId
                  }
                }

                date
                location {
                  venue
                  city
                  country
                }
                ticket {
                  status
                  releaseDate
                  link
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <ArtistEvents data={data} {...props} />}
  />
);

// TODO: Add prop types
