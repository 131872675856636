import React from "react";
import { graphql } from "gatsby";

import ArtistTemplate from "src/components/PageTemplates/ArtistTemplate/ArtistTemplate";
import ArtistEventsTemplate from "src/components/PageTemplates/ArtistEventsTemplate/ArtistEventsTemplate";
import ArtistAgencyTemplate from "src/components/PageTemplates/ArtistAgencyTemplate/ArtistAgencyTemplate";

const ArtistPage = (props) => {
  const { data, pageContext } = props;
  const { template } = pageContext;
  switch (template) {
    case "artist-events":
      return <ArtistEventsTemplate gatsbyData={data.wpArtist} />;
    case "artist-agency":
      return <ArtistAgencyTemplate gatsbyData={data.wpArtist} />;
    default:
      return <ArtistTemplate gatsbyData={data.wpArtist} />;
  }
};

export default ArtistPage;

export const query = graphql`
  query currentArtistQuery($id: String!) {
    wpArtist(id: { eq: $id }) {
      link
      title
      databaseId
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      acfArtistPage {
        cta {
          use
          action
          link {
            title
            url
          }
        }
        country
        agent {
          ... on WpStaff {
            databaseId
          }
        }
        agentRole
        links {
          label
          link {
            title
            url
          }
          display
        }
        workAreas {
          area
        }
        introduction {
          headline
          preamble
        }
        chapters {
          title
          text
          images {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: DOMINANT_COLOR
                    width: 1920
                  )
                }
              }
            }
          }
        }
        video {
          use
          service
          youtubeId
          vimeoId
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: DOMINANT_COLOR
                  width: 1920
                )
              }
            }
          }
        }
        release {
          use
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: DOMINANT_COLOR
                  width: 1920
                )
              }
            }
          }
          spotifyUri
        }
        relatedArtists {
          use
          artists {
            artist {
              ... on WpArtist {
                databaseId
              }
            }
          }
        }
      }
    }
  }
`;

// TODO: ADD PROP TYPES
