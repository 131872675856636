import React from "react";
import url from "url";
import getImageObject from "src/helpers/getImageObject";

import SEO from "src/components/Modules/SEO";
import PageStyles from "src/components/styles/PageStyles";
import ContentWrapper from "src/components/UI/Structure/ContentWrapper";
import Hero from "src/components/Modules/Hero";
import ArtistEvents from "src/components/Artist/ArtistEvents";
import SectionLight from "src/components/Modules/SectionLight";
import Flex from "src/components/UI/Structure/Flex";
import Text from "src/components/UI/Text";

const ArtistEventsTemplate = ({ gatsbyData }) => {
  const artist = gatsbyData;
  let { title, featuredImage, link, acfArtistPage } = artist;
  const { introduction } = acfArtistPage;
  const images = getImageObject(featuredImage);
  let seoDescription = "";
  if (introduction && introduction.preamble) {
    seoDescription = introduction.preamble.replace(/<\/?[^>]+(>|$)/g, "");

    if (seoDescription.length > 220) {
      seoDescription = seoDescription.slice(0, 220) + "...";
    }
  }
  return (
    <PageStyles>
      <SEO title={`Konserter — ${title}`} />
      <SectionLight>
        <Hero
          size="medium"
          largeHeadline
          headerColor="light"
          title={title}
          images={images}
          label="Konserter"
          link={link ? url.parse(link).pathname : ""}
          linkTitle="Visa artist"
          buttonLayout={{
            filled: "white",
            defaultStyle: 0,
          }}
          buttonContent={
            <Flex width={1} alignItems="center">
              <Text fontSize={2} fontWeight="bold" letterSpacing="0.75px">
                Visa artist
              </Text>
              <Text fontSize={2} ml="auto" pl="10" fontWeight="bold">
                →
              </Text>
            </Flex>
          }
        />
      </SectionLight>
      <ContentWrapper>
        <ArtistEvents artist={artist} />
      </ContentWrapper>
    </PageStyles>
  );
};

export default ArtistEventsTemplate;
