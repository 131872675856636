import React, { Component } from "react";

import getImageObject from "src/helpers/getImageObject";

import SectionLight from "src/components/Modules/SectionLight";
import PageStyles from "src/components/styles/PageStyles";
import ContentWrapper from "src/components/UI/Structure/ContentWrapper";
import Container from "src/components/UI/Grid/Container";
import Grid from "src/components/UI/Grid/Grid";
import Column from "src/components/UI/Grid/Column";
import Box from "src/components/UI/Structure/Box";

import SEO from "src/components/Modules/SEO";
import Hero from "src/components/Modules/Hero";
import LinkList from "src/components/Artist/LinkList";
import WorkAreas from "src/components/Artist/WorkAreas";
import Territories from "src/components/Artist/Territories";
import ArtistAgent from "src/components/Artist/ArtistAgent";

class ArtistAgencyTemplate extends Component {
  render() {
    // Setup gatsby data
    const { gatsbyData } = this.props;

    // Choose based on gatsby or preview data
    const artist = gatsbyData;
    const { title, featuredImage, acfArtistPage } = artist;
    const images = getImageObject(featuredImage);

    const {
      country,
      introduction,
      links,
      agent,
      workAreasAgency,
      territories,
    } = acfArtistPage;

    let seoDescription = "";
    if (introduction && introduction.preamble) {
      seoDescription = introduction.preamble.replace(/<\/?[^>]+(>|$)/g, "");

      if (seoDescription.length > 220) {
        seoDescription = seoDescription.slice(0, 220) + "...";
      }
    }
    return (
      <PageStyles>
        <SEO title={title} description={seoDescription} ogImage={images} />
        <SectionLight>
          <Hero
            label="Agency"
            size="medium"
            headerColor="light"
            title={title}
            titleExtra={country && country !== "none" ? `(${country})` : ""}
            largeHeadline
            images={images}
          />
        </SectionLight>
        <ContentWrapper>
          <Container>
            {links && agent && (
              <Grid justifyContent="space-between" alignItems="center">
                {/* Links */}
                <Column
                  width={[12 / 12, null, null, 6 / 12]}
                  mb={[8, null, null, 15]}
                >
                  <Box
                    ml={["-32px", null, null, null, 0]}
                    mr={["-32px", null, null, null, 0]}
                    px={["32px", null, null, null, 0]}
                    py={[8, null, null, "160px"]}
                    bg="dark"
                  >
                    <Grid justifyContent="center">
                      <Column
                        width={[12 / 12, null, null, 10 / 12, 8 / 12]}
                        mb={0}
                      >
                        <LinkList links={links} light division="agency" />
                      </Column>
                    </Grid>
                  </Box>
                </Column>
                {/* Contact */}
                <Column
                  width={[12 / 12, null, null, 5 / 12]}
                  mb={[8, null, null, 15]}
                >
                  <Grid>
                    <Column
                      width={[12 / 12, null, 6 / 12, 7 / 12]}
                      mb={[5, null, null, null, 0]}
                    >
                      <ArtistAgent
                        agent={agent}
                        artist={title}
                        english
                        label="Contact"
                      />
                    </Column>
                    <Column width={[12 / 12, null, 6 / 12, 5 / 12]}>
                      {workAreasAgency && (
                        <Box mb={4}>
                          <WorkAreas
                            label="What we do"
                            areas={workAreasAgency}
                          />
                        </Box>
                      )}
                      {territories && (
                        <Territories
                          label="Territories"
                          territories={territories}
                        />
                      )}
                    </Column>
                  </Grid>
                </Column>
              </Grid>
            )}
          </Container>
        </ContentWrapper>
      </PageStyles>
    );
  }
}

export default ArtistAgencyTemplate;
